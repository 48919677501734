import GoalsDropdown from '../goal-dropdown'
import { useClientReferral } from 'providers/client-referral-provider'
import s from './styles.module.css'
import { HeroMedia } from './hero-media'
import { useClientTrialDays } from 'hooks/use-client-trial-days'
import { getTrialDaysDuration } from 'utilities/stripe'

const HeroExperiment = ({ fields, landingPageOptions }) => {
  const { clientFirstName } = useClientReferral()
  const trialDays = useClientTrialDays()

  const { header, subHeader, video, options = {} } = fields
  const { dropdownMenuPlacement } = options

  const title = header
    .replace('{firstName}', clientFirstName)
    .replace(/^(.*?\s){4}/, '$&\n')
  const subTitle = subHeader
    ?.replace('{firstName}', clientFirstName)
    ?.replace('{period}', getTrialDaysDuration(trialDays))

  return (
    <section className={s.container}>
      <HeroMedia videoFields={video?.fields} />
      <div className={s.content}>
        <div className={s.titleContainer}>
          <h1 className={s.title}>{title}</h1>
          {subTitle && <p className={s.subTitle}>{subTitle}</p>}
        </div>
        <div className={s.contentContainer}>
          <GoalsDropdown
            analyticProps={{ location: 'Hero' }}
            landingPageOptions={landingPageOptions}
            menuPlacement={dropdownMenuPlacement}
          />
          <img
            className={s.ratingsImage}
            src="https://res.cloudinary.com/kickoff-personal-training/image/upload/f_auto,q_auto/v1/static/Ratings_Laurel_Asset_1_lobjf9"
          />
        </div>
      </div>
    </section>
  )
}

export default HeroExperiment
